import { Html, useProgress } from '@react-three/drei';
import styled from 'styled-components';

import { theme } from '../styles/theme';

const Container = styled.div<{ procent: number }>`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme, procent }) => `rgba${(theme.colors.black, procent / 100)}`};
  color: ${({ theme }) => theme.colors.green};
  display: ${({ procent }) => (procent === 100 ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  position: relative;
  overflow: hidden;

  > span {
    z-index: 99;
    color: white;
  }
  span:first-child {
    color: ${({ theme }) => theme.colors.green};
  }
`;

const Triangles = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
`;

const HeaderTriangle = styled.div<{ width: number }>`
  width: 100vw;
  height: 100vh;
  clip-path: ${({ width }) => `polygon(0% 0%, ${width}% 0%, 0% 100%)`};
  background-color: ${({ theme }) => theme.colors.green};
`;

const BottomTriangle = styled(HeaderTriangle)`
  background-color: ${({ theme }) => theme.colors.white};
  margin-left: calc(-100vw);
  clip-path: ${({ width }) => `polygon(0% 100%, 100% 100%, 100% ${width}%)`};
`;

export const Loader = () => {
  const { progress } = useProgress();
  const procent = 100 - progress;

  return (
    <Html center>
      <Container theme={theme} procent={procent}>
        <span>{Math.floor(progress)}</span>
        <span> % loaded</span>
        <Triangles>
          <HeaderTriangle theme={theme} width={procent} />
          <BottomTriangle theme={theme} width={progress} />
        </Triangles>
      </Container>
    </Html>
  );
};
