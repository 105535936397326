import styled from 'styled-components';
import { GitHub, LinkedIn, Mail } from '@mui/icons-material';
import { useSelector } from 'react-redux';

import { CONTACT_URLS } from '../../types/enums';
import { Arrow } from './common';
import { Button } from '../button';
import { selectInterface } from '../../redux/slices/interface-slice';

const Container = styled.div<{ height: number }>`
  align-self: flex-end;
  display: inline-flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 300px;
  z-index: 999;
  margin-right: ${({ height }) => -64 + height * 0.8}px;

  > span {
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    color: #9c9c9c;
  }
`;

export const Contacts = () => {
  return (
    <Container height={useSelector(selectInterface).bodyPadding}>
      <Arrow />
      <Button>
        <a href={CONTACT_URLS.GITHUB} target="_blank" rel="noopener noreferrer">
          <GitHub />{' '}
        </a>
      </Button>
      <Button>
        <a href={CONTACT_URLS.MAIL}>
          <Mail />
        </a>
      </Button>
      <Button>
        <a href={CONTACT_URLS.LINKEDIN} target="_blank" rel="noopener noreferrer">
          <LinkedIn />{' '}
        </a>
      </Button>
      <span>2023</span>
    </Container>
  );
};
