import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Divider } from './common';
import { theme } from '../../styles/theme';
import { selectInterface } from '../../redux/slices/interface-slice';

const Container = styled.div<{ height: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  color: #fff;
  gap: 8px;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  height: 80px;
  margin-right: ${({ height }) => -64 + height * 0.8}px;

  > span {
    font-weight: bold;
  }
`;

export const Title = () => {
  const padding = useSelector(selectInterface).bodyPadding;
  const sketchesMode = useSelector(selectInterface).sketchesMode;

  return (
    <Container height={useSelector(selectInterface).bodyPadding}>
      <span>3D {sketchesMode ? 'sketch' : 'sketches'}</span>
      by v. bolonicheva aka morphoix
      <Divider theme={theme} width={padding * 1.25} />
    </Container>
  );
};
