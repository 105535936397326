import styled from 'styled-components';

import { Trail } from '../trail';
import { FOOTER_BUTTONS } from '../../types/enums';
import { Arrow } from '../visit-card/common';
import { theme } from '../../styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { Navigation } from '../navigation';
import { selectInterface, setNavOpen, setSketchesMode } from '../../redux/slices/interface-slice';

const MenuButton = styled.div<{ showAbout?: boolean }>`
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 4px 10px;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  color: ${({ showAbout, theme }) => (showAbout ? theme.colors.green : 'white')};
  font-size: 0.8rem;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.4s all;
  gap: 8px;
  width: ${({ showAbout }) => (showAbout ? '100px' : '100%')};

  :hover {
    color: ${({ theme }) => theme.colors.green};
  }
`;

const FooterContainer = styled.div<{ open?: boolean }>`
  position: absolute;
  height: 80px;
  align-self: flex-end;
  display: inline-flex;
  align-items: center;
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: 0.4s all;
  gap: 8px;
  bottom: 0;
  left: 80px;
`;

interface FooterControlsProps {
  textOpen: boolean;
  showAbout: boolean;
  handleAbout: () => void;
}

export const FooterControls = ({ textOpen, showAbout, handleAbout }: FooterControlsProps) => {
  const dispatch = useDispatch();
  return (
    <FooterContainer open={textOpen}>
      <Trail open={!textOpen}>
        <MenuButton theme={theme} onClick={() => dispatch(setSketchesMode(true))}>
          <Arrow />
          sketches
        </MenuButton>
        <MenuButton onClick={handleAbout} showAbout={showAbout} theme={theme}>
          <Arrow active={showAbout} />
          {FOOTER_BUTTONS.BUTTON_1}
        </MenuButton>
      </Trail>
    </FooterContainer>
  );
};
////////////////////////////////////////////////////////////////////////////////////////
const HeaderContainer = styled(FooterContainer)<{ navOpen: boolean; hide: boolean }>`
  opacity: 1;
  top: 0;
  left: 0;
  min-height: 80px;
  bottom: unset;
  padding: 10px 0;
  margin-left: ${({ navOpen }) => (navOpen ? '-105px' : '-70px')};
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
`;

export const HeaderControls = () => {
  const dispatch = useDispatch();
  const interfaceState = useSelector(selectInterface);

  const toggleNavBar = () => {
    dispatch(setNavOpen(!interfaceState.navOpen));
  };

  return (
    <HeaderContainer navOpen={interfaceState.navOpen} hide={!interfaceState.sketchesMode}>
      <Trail open={interfaceState.navOpen}>
        <MenuButton theme={theme} onClick={toggleNavBar}>
          <Arrow active={interfaceState.navOpen} />
          {interfaceState.navOpen ? 'close' : 'sketches'}
        </MenuButton>
      </Trail>
      <Navigation open={interfaceState.navOpen} />
    </HeaderContainer>
  );
};
