import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';

import { RootState } from './store';
import { setPage } from './slices/homePage-slice';
import { setRoute } from './slices/route-slice';

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  matcher: isAnyOf(setPage, setRoute),
  effect: (_action, listenerApi) => {
    localStorage.setItem('home', JSON.stringify((listenerApi.getState() as RootState).home));
    localStorage.setItem('route', JSON.stringify((listenerApi.getState() as RootState).route));
  },
});
