import { MutableRefObject, ReactNode, useEffect, useLayoutEffect } from 'react';
import { Html } from '@react-three/drei';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import { useSpring, useSpringRef, animated } from '@react-spring/web';

import { ScrollSection } from './scroll-section';
import { useSelector } from 'react-redux';
import { selectHome } from '../redux/slices/homePage-slice';

const Container = styled.div`
  margin: 0 auto;
  transition: 0.4s ease-in-out;
  width: 100%;
  max-width: 100%;
  height: 100%;
`;

interface HTMLContentProps {
  children?: ReactNode;
  domContent: MutableRefObject<HTMLDivElement | null>;
}

export const HTMLContent = ({ domContent, children }: HTMLContentProps) => {
  const [refItem, inView] = useInView({ threshold: 0 });
  const springApi = useSpringRef();
  const homeState = useSelector(selectHome);
  const position = homeState.page?.position as number;

  useEffect(() => {
    inView && (document.body.style.background = homeState.page?.color as string);
  }, [inView, homeState.page?.color]);

  const springs = useSpring({
    from: {
      strokeDashoffset: 120,
    },
    to: {
      strokeDashoffset: 0,
    },
    config: {
      duration: 11000,
    },
    loop: true,
    ref: springApi,
  });

  useLayoutEffect(() => {
    springApi.start();
  }, [springApi]);

  return (
    <ScrollSection factor={1.5} offset={1}>
      <group position={[0, position, 0]}>
        <Html fullscreen portal={domContent as MutableRefObject<HTMLDivElement>}>
          <Container ref={refItem}>
            <animated.div style={springs}>{children}</animated.div>
          </Container>
        </Html>
      </group>
    </ScrollSection>
  );
};
