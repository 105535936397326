import { Mesh } from 'three';
import { useRef } from 'react';
import { useVideoTexture } from '@react-three/drei';

export const Screen = () => {
  const plane = useRef<Mesh>(null);
  return (
    <mesh ref={plane} position={[0, 8, 110]}>
      <planeGeometry attach="geometry" args={[60, 30]}></planeGeometry>
      <VideoMaterial url={'./assets/videos/matrix.mp4'} />
    </mesh>
  );
};

function VideoMaterial({ url }: { url: string }) {
  const texture = useVideoTexture(url);
  return <meshBasicMaterial map={texture} toneMapped={false} />;
}

// const PLANE = {
//   width: 10,
//   height: 10,
//   widthSegments: 40,
//   heightSegments: 40,
// };

// const Plane = () => {
//   const plane = useRef<Mesh>();

//   useEffect(() => {
//     if (plane.current !== undefined) {
//       plane.current.geometry.dispose();
//       const { array } = plane.current.geometry.attributes.position;
//       for (let i = 0; i < array.length; i++) {
//         if (i % 3 === 0) {
//           const x = array[i + 1];
//           array[i] = x + (Math.random() - 0.5) * 3;
//         }
//       }
//     }
//   }, []);

//   let t = 0;
//   useFrame(() => {
//     t = timeIterator(t);
//     if (plane.current !== undefined) {
//       let i = 0;
//       for (let yi = 0; yi < PLANE.widthSegments; yi++) {
//         for (let xi = 0; xi < PLANE.heightSegments; xi++) {
//           const positions = plane.current.geometry.attributes.position.array as number[];
//           positions[i + 2] = updateGeometryPositions(positions[i], positions[i + 1], t);
//           i += 3;
//         }
//       }
//       plane.current.geometry.attributes.position.needsUpdate = true;
//     }
//   });

//   return (
//     <mesh ref={plane} position={[0, 0, 118]}>
//       <planeGeometry
//         attach="geometry"
//         args={[PLANE.width, PLANE.height, PLANE.widthSegments, PLANE.heightSegments]}
//       ></planeGeometry>
//       <meshPhysicalMaterial
//         attach="material"
//         color={0xffd3f2}
//         emissive={0x5e6b7f}
//         metalness={1}
//         reflectivity={1}
//         roughness={0.2}
//         flatShading={true}
//         clearcoat={1}
//         clearcoatRoughness={0}
//       />
//     </mesh>
//   );
// };
