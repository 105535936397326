export const TRIANGLE_TOP = {
  clipPath: 'polygon(0% 0%, 100% 0%, 0% 100%)',
  justifyContent: 'flex-start',
  innerDiv: 'polygon(0% 0%, 100% 0%, 0% 99%)',
};

export const TRIANGLE_BOTTOM = {
  clipPath: 'polygon(0% 100%, 100% 100%, 100% 0%)',
  justifyContent: 'flex-end',
  marginLeft: '-92px',
  innerDiv: 'polygon(0% 100%, 100% 100%, 100% 1%)',
};
