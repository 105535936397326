import styled from 'styled-components';
import { theme } from '../../styles/theme';

export const Divider = styled.div<{ active?: boolean; vertical?: boolean; width?: number }>`
  height: 1px;
  width: ${({ width }) => `${width}%`};
  background: ${({ theme, active }) => (active ? 'transparent' : theme.colors.white)};
  position: relative;
  transition: 0.4s all;
  transform: ${({ vertical }) => (vertical ? 'rotate(90deg)' : 'rotate(0deg)')};

  > span {
    height: 1px;
    width: ${({ active }) => (active ? '14px' : '7px')};
    top: ${({ active }) => (active ? '-4px' : '-2px')};
    right: 0;
    transform: ${({ active }) => (active ? 'rotate(-45deg)' : 'rotate(-35deg)')};
    position: absolute;
    background: ${({ theme, active }) => (active ? theme.colors.green : theme.colors.white)};
    display: block;
    margin: 4px 0;
    transition: 0.4s all;
  }

  > span:last-child {
    transform: ${({ active }) => (active ? 'rotate(45deg)' : 'rotate(35deg)')};
    top: ${({ active }) => (active ? '-4px' : '-7px')};
  }
`;

interface ArrowProps {
  active?: boolean;
  vertical?: boolean;
}

export const Arrow = ({ active, vertical }: ArrowProps) => {
  return (
    <Divider theme={theme} active={active} vertical={vertical} width={100}>
      <span />
      <span />
    </Divider>
  );
};
