import { useTrail, a } from '@react-spring/web';
import { Children } from 'react';

type Props = { open: boolean; children: React.ReactNode };

export const Trail = ({ open, children }: Props) => {
  const items = Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 1000, friction: 200 },
    width: open ? 100 : 170,
    from: { opacity: 1, height: 60, width: 170 },
  });

  return (
    <>
      {trail.map(({ ...style }, index) => (
        <a.div key={index} style={{ ...style, width: style.width }}>
          <a.div style={style}>{items[index]}</a.div>
        </a.div>
      ))}
    </>
  );
};
