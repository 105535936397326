import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface InterfaceState {
  navOpen: boolean;
  scroll: number;
  sketchesMode: boolean;
  bodyPadding: number;
}

const initialState: InterfaceState = {
  navOpen: true,
  scroll: 0,
  sketchesMode: false,
  bodyPadding: 0,
};

export const interfaceSlice = createSlice({
  name: 'interface',
  initialState,
  reducers: {
    setNavOpen: (state, action) => {
      state.navOpen = action.payload;
    },
    setScroll: (state, action) => {
      state.scroll = action.payload;
    },
    setSketchesMode: (state, action) => {
      state.sketchesMode = action.payload;
    },
    setBodyPadding: (state, action) => {
      state.bodyPadding = action.payload;
    },
  },
});

export const { setNavOpen, setScroll, setSketchesMode, setBodyPadding } = interfaceSlice.actions;
export default interfaceSlice.reducer;

export const selectInterface = (state: RootState) => {
  return state.interface;
};
