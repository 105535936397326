import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { VisitCard } from './visit-card/visit-card';
import { routes, selectRoute } from '../redux/slices/route-slice';
import { theme } from '../styles/theme';
import { HeaderControls } from './home/controls';
import { selectInterface, setBodyPadding, setScroll, setSketchesMode } from '../redux/slices/interface-slice';
import { useEffect } from 'react';
import { Logout } from '@mui/icons-material';

export const BODY_PADDING = 80;

const Container = styled.div<{ lastRoute?: boolean; sketchesMode: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ lastRoute }) => (lastRoute ? 'calc(100% - 110px)' : `calc(100% - ${BODY_PADDING * 2}px)`)};
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin: ${({ lastRoute }) => (lastRoute ? `0 0 0 ${BODY_PADDING}px` : `0 ${BODY_PADDING}px`)};
  transition: all 0.4s ease;
`;

const InfoWrapper = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  padding: 12px 16px 10px 16px;
  padding-top: 0;
  font-size: 12px;
  gap: 8px;
  font-family: 'Roboto', sans-serif;
  color: ${({ theme }) => theme.colors.white};
  z-index: 1000;

  > div > span {
    font-weight: bold;
  }

  > div:first-child {
    font-size: 14rem;
    font-family: 'Odyssee ITC Std Light', sans-serif;
    color: ${({ theme }) => theme.colors.black};
  }
`;

const LeftButton = styled.div`
  align-self: center;
  position: absolute;
  left: 0;
  margin-left: -60px;
  width: 50px;
  height: 25%;
  background-color: #fefefe;
  clip-path: polygon(100% 0, 100% 100%, 0 50%);
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.8);
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 100%;
    height: 100%;
    background-color: #0c0c0c;
    color: #fefefe;
    clip-path: polygon(100% 1%, 100% 99%, 0 50%);
  }

  :hover {
    background-color: #03fe86;
  }
`;

const RightButton = styled(LeftButton)<{ lastRoute?: boolean }>`
  left: unset;
  right: 6px;
  margin-left: 0;
  margin-right: ${({ lastRoute }) => (lastRoute ? '-166px' : '-66px')};
  transition: all 0.4s ease-in-out;
  clip-path: polygon(0 0, 100% 50%, 0 100%);

  > div {
    clip-path: polygon(0 1%, 100% 50%, 0 99%);
  }
`;

export const Overlay = () => {
  const data = routes;
  const route = useSelector(selectRoute);
  const navigate = useNavigate();
  const lastRoute = route.id === data.length - 1;
  const firstRoute = route.id === 0;
  const sketchesMode = useSelector(selectInterface).sketchesMode;
  const dispatch = useDispatch();

  useEffect(() => {
    if (sketchesMode) {
      dispatch(setBodyPadding(80));
      document.getElementById('root')?.setAttribute('style', `padding: 80px`);
      dispatch(setScroll(1));
    } else document.getElementById('root')?.setAttribute('style', `padding: 0px`);
  }, [sketchesMode, dispatch]);

  const redirectToNextPage = () => {
    navigate(data[route.id + 1].route);
  };

  const handleLeftButton = (id: number) => {
    if (id === 0 && sketchesMode) {
      dispatch(setSketchesMode(false));
    }
    !firstRoute && navigate(data[route.id - 1].route);
  };

  return (
    <Container lastRoute={lastRoute} sketchesMode={sketchesMode}>
      {sketchesMode && <HeaderControls />}
      {sketchesMode && (
        <LeftButton onClick={() => handleLeftButton(route.id)}>
          {!firstRoute ? <div /> : <Logout style={{ transform: 'rotate(180deg)' }} />}
        </LeftButton>
      )}
      {sketchesMode && (
        <>
          <InfoWrapper theme={theme}>
            <div>{route.title}</div>
            <div>
              <span>posted: </span> {route.data}
            </div>
            <div>
              <span>tools: </span>: {route.tools}
            </div>
            <div>{route.description}</div>
          </InfoWrapper>
          <RightButton onClick={redirectToNextPage} lastRoute={lastRoute}>
            <div />
          </RightButton>
        </>
      )}
      <VisitCard />
    </Container>
  );
};
