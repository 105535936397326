export enum BG_COLOR {
  COLOR_1 = 'rgb(12, 12, 12)',
  COLOR_2 = 'rgb(0, 0, 0)',
  COLOR_3 = 'rgb(12, 12, 12)',
}
export const { COLOR_1, COLOR_2, COLOR_3 } = BG_COLOR;

export enum FOOTER_BUTTONS {
  BUTTON_1 = `about`,
  BUTTON_2 = 'portfolio',
}

export enum CONTACT_URLS {
  LINKEDIN = 'https://www.linkedin.com/in/valeria-bolonicheva/',
  GITHUB = 'https://github.com/morphoix',
  MAIL = 'mailto:morphoix@gmail.com',
}
