import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { routes, selectRoute } from '../redux/slices/route-slice';

const StyledIframe = styled.iframe<{ lastRoute?: boolean; load: boolean }>`
  overflow: hidden;
  transition: all 0.5s ease;
  border-radius: 2px;
  z-index: 999;
  opacity: ${({ load }) => (load ? 0 : 1)};
  transform: ${({ load }) => (load ? 'scale(0.5)' : 'scale(1)')};
  position: relative;
`;

const IframeWrapper = () => {
  const route = useSelector(selectRoute);
  const lastRoute = route.id === routes.length - 1;
  const [load, setLoad] = useState(true);

  useEffect(() => {
    setLoad(true);
  }, [route]);

  return (
    <StyledIframe
      src={route.path}
      lastRoute={lastRoute}
      width={'100%'}
      height={'100%'}
      title={route.title}
      onLoad={() => setLoad(false)}
      load={load}
    ></StyledIframe>
  );
};

export default IframeWrapper;
