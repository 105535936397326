import { Close } from '@mui/icons-material';
import styled from 'styled-components';
import { Html } from '@react-three/drei';

import { Button } from '../button';
import { theme } from '../../styles/theme';

const Container = styled.div`
  width: 380px;
  height: auto;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: ${({ theme }) => theme.colors.white};
  padding: 4px 8px 24px 16px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-align: justify;
  line-height: 21px;

  > button {
    align-self: flex-end;

    :hover {
      color: ${({ theme }) => theme.colors.green};
    }
  }
`;

interface AboutProps {
  handleAbout: () => void;
}

export const About = ({ handleAbout }: AboutProps) => {
  return (
    <Html position={[-60, 50, 0]}>
      <Container theme={theme}>
        <Button onClick={handleAbout}>
          <Close />
        </Button>
        <h3>About</h3>
        <p>
          Hello, <b>`m Lera aka morphoix</b>, a passionate digital developer with a knack for crafting innovative and
          engaging digital experiences.
          <br /> With a love for both design and development, I bridge the gap between creativity and technical
          precision.
        </p>
        <h3>What drives me</h3>
        <p>
          My journey in the digital realm is a blend of curiosity, creativity, and continuous learning.
          <br />I thrive on the challenges posed by this ever-evolving landscape, always striving to push the boundaries
          of what`s possible
        </p>
        <p>
          Interested in working together or simply want to discuss a digital project? Feel free to reach out to me.
          <br /> Let`s embark on a digital journey to create something extraordinary :)
        </p>
      </Container>
    </Html>
  );
};
