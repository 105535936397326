import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

type Page = {
  number: number;
  color: string;
  position: number;
};

interface HomePageState {
  page: Page | null;
}

const initialState: HomePageState = {
  page: null,
};

export const homePageSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const selectHome = (state: RootState) => {
  return state.home;
};

export const { setPage } = homePageSlice.actions;
export default homePageSlice.reducer;
