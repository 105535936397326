import { Merged, useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useMemo, useRef } from 'react';
import * as THREE from 'three';
import { type GLTF } from 'three-stdlib';
// import * as dat from 'lil-gui';
// eslint-disable-next-line
// @ts-ignore
import maskVertexShader from './shaders/mask/vertexShader.glsl';
// eslint-disable-next-line
// @ts-ignore
import maskFragmentShader from './shaders/mask/fragmentShader.glsl';
import { About } from '../home/about';
import { RigidPlane } from './rigid-plane';
import { useSelector } from 'react-redux';
import { selectInterface } from '../../redux/slices/interface-slice';

// eslint-disable-next-line
type GLTFResult = GLTF & {
  nodes: {
    GuyFawkesMask: THREE.Mesh;
  };
  materials: {
    ['Material.001']: THREE.ShaderMaterial;
  };
};
interface MaskProps {
  look: boolean;
  showAbout: boolean;
  handleAbout: () => void;
}

const Mask = ({ look, showAbout, handleAbout }: MaskProps) => {
  const { nodes } = useGLTF('assets/models/mask-transformed.glb') as GLTFResult;
  // eslint-disable-next-line
  const maskRef = useRef<any | null>(null);
  // const gui = new dat.GUI();
  const debugObject = { depthColor: '#ff4d00', surfaceColor: '#ffea00' };
  const scroll = useSelector(selectInterface).scroll;
  const sketchMode = useSelector(selectInterface).sketchesMode;

  const instances = useMemo(
    () => ({
      Object1: nodes.GuyFawkesMask,
    }),
    [nodes],
  );

  const uniforms = useMemo(() => {
    return {
      uTime: { value: 0 },
      uBigWavesElevation: { value: 0.2 },
      uBigWavesFrequency: { value: new THREE.Vector2(4, 1.5) },
      uBigWavesSpeed: { value: 0.75 },
      uSmallWavesElevation: { value: 0.15 },
      uSmallWavesFrequency: { value: 3 },
      uSmallWavesSpeed: { value: 0.2 },
      uSmallIterations: { value: 4 },
      uDepthColor: { value: new THREE.Color(debugObject.depthColor) },
      uSurfaceColor: { value: new THREE.Color(debugObject.surfaceColor) },
      uColorOffset: { value: 0.08 },
      uColorMultiplier: { value: 5 },
    };
  }, [debugObject.depthColor, debugObject.surfaceColor]);

  // gui.addColor(debugObject, 'depthColor').onChange(() => {
  //   maskRef.current!.material.uniforms.uDepthColor.value.set(debugObject.depthColor);
  // });
  // gui.addColor(debugObject, 'surfaceColor').onChange(() => {
  //   maskRef.current!.material.uniforms.uSurfaceColor.value.set(debugObject.surfaceColor);
  // });

  useFrame(({ clock, mouse, viewport, camera }) => {
    const elapsedTime = clock.getElapsedTime();
    maskRef.current.material.uniforms.uTime.value = elapsedTime;

    const x = (mouse.x * viewport.width) / 2.5;
    const y = (mouse.y * viewport.height) / 2.5;
    look && !showAbout ? maskRef.current.lookAt(x, y, 1) : maskRef.current.lookAt(0, 0, 150);

    const lerpY = sketchMode ? 10 : Math.floor(scroll * 10);
    camera.position.lerp(new THREE.Vector3(5 - lerpY / 2, 1.2 + lerpY / 2, 138 + lerpY), 0.05);
  });

  const vertexShader = useMemo(() => {
    return maskVertexShader;
  }, []);

  const fragmentShader = useMemo(() => {
    return maskFragmentShader;
  }, []);

  return (
    <Merged meshes={instances}>
      {() => (
        <group>
          <mesh position={[0, 9, 110]} castShadow>
            <planeGeometry args={[17, 20]} />
            <meshBasicMaterial color="#000" transparent={true} opacity={0.99} />
          </mesh>
          <mesh
            castShadow
            geometry={instances.Object1.geometry}
            position={[0, 0.8, 130]}
            scale={0.2}
            rotation={[0, 0, 0]}
            ref={maskRef}
            material={nodes.GuyFawkesMask.material}
          >
            <shaderMaterial
              attach="material"
              wireframe={true}
              wireframeLinewidth={1}
              fragmentShader={fragmentShader}
              vertexShader={vertexShader}
              uniforms={uniforms}
              side={THREE.BackSide}
            />
            {showAbout ? <About handleAbout={handleAbout} /> : null}
          </mesh>
          <RigidPlane rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 130]} />
        </group>
      )}
    </Merged>
  );
};

useGLTF.preload('assets/models/mask-transformed.glb');

export default Mask;
