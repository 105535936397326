import styled from 'styled-components';
import { theme } from '../styles/theme';

const Container = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  font-size: 1.5rem;
  transition: 0.3s;
  outline: none;
  min-width: 48px;
  min-height: 48px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: ${({ theme }) => theme.colors.white};
  }

  a:hover {
    color: ${({ theme }) => theme.colors.green};
  }
`;

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
}

export const Button = ({ children, onClick }: ButtonProps) => {
  return (
    <Container theme={theme} onClick={onClick}>
      {children}
    </Container>
  );
};
