import { configureStore } from '@reduxjs/toolkit';

import { listenerMiddleware } from './middleware';
import homePageReducer from './slices/homePage-slice';
import routeReducer from './slices/route-slice';
import interfaceReducer from './slices/interface-slice';

const homePageState = JSON.parse(localStorage.getItem('home') || 'null');
const routeState = JSON.parse(localStorage.getItem('route') || 'null');

export const store = configureStore({
  preloadedState: {
    home: homePageState === null ? {} : homePageState,
    route: routeState === null ? {} : routeState,
  },
  reducer: {
    home: homePageReducer,
    route: routeReducer,
    interface: interfaceReducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(listenerMiddleware.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
