import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { animated, config, useChain, useSpring, useSpringRef, useTransition } from '@react-spring/web';
import { useDispatch, useSelector } from 'react-redux';

import styles from '../styles/navigation.module.css';
import { routes, selectRoute, setRoute } from '../redux/slices/route-slice';
import { TRIANGLE_BOTTOM, TRIANGLE_TOP } from '../constants';
import { renderId } from '../utils/css-util';

interface NavigationProps {
  open?: boolean;
}

export const Navigation = ({ open }: NavigationProps) => {
  const currentRoute = useSelector(selectRoute);
  const dispatch = useDispatch();
  const location = useLocation();
  const [data] = useState(routes);

  useEffect(() => {
    dispatch(setRoute(location.pathname));
  }, [location.pathname, dispatch]);

  const springApi = useSpringRef();
  const { size, ...rest } = useSpring({
    ref: springApi,
    config: config.stiff,
    from: { size: '20%' },
    to: {
      size: open ? '100%' : '20%',
    },
  });

  const transApi = useSpringRef();
  const transition = useTransition(open ? data : [], {
    ref: transApi,
    trail: 400 / data.length,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0 },
  });

  useChain(open ? [springApi, transApi] : [transApi, springApi], [0, open ? 0.1 : 0.6]);
  return currentRoute.title !== 'home' ? (
    <>
      <animated.div style={{ ...rest, width: size, height: size }} className={styles.container}>
        {transition((style, item) => (
          <NavLink to={item.route || '/'}>
            <animated.div
              className={currentRoute.title === item.title ? styles.activeRoute : styles.route}
              style={renderId(item.id) === 'binary' ? { ...style, ...TRIANGLE_TOP } : { ...style, ...TRIANGLE_BOTTOM }}
            >
              <div
                style={{
                  clipPath: renderId(item.id) === 'binary' ? TRIANGLE_TOP.innerDiv : TRIANGLE_BOTTOM.innerDiv,
                  background: '#0c0c0c',
                  width: '97%',
                  height: '100%',
                  display: 'flex',
                  padding: '7px',
                  justifyContent: renderId(item.id) === 'binary' ? 'flex-start' : 'flex-end',
                }}
              >
                <span
                  className={styles.innerText}
                  style={{ marginTop: renderId(item.id) === 'binary' ? '0px' : '29px ' }}
                >
                  {item.title}
                </span>
              </div>
            </animated.div>
          </NavLink>
        ))}
      </animated.div>
    </>
  ) : null;
};
