import { useFrame } from '@react-three/fiber';
import { STATE, useSection, offsetContext } from '../hooks/useSection';
import { ReactElement, useRef } from 'react';
import { MathUtils, Group } from 'three';

interface ScrollSectionProps {
  children: ReactElement;
  offset: number;
  factor: number;
}

export const ScrollSection = ({ children, offset, factor }: ScrollSectionProps) => {
  const { offset: parentOffset, sectionHeight, aspect } = useSection();
  const ref = useRef<Group>(null);
  offset = offset !== undefined ? offset : parentOffset;

  useFrame(() => {
    const curY = ref.current?.position.y as number;
    const curTop = (STATE.top.current as number) / aspect;
    if (ref.current !== null) {
      ref.current.position.y = MathUtils.lerp(curY, (curTop / STATE.zoom) * factor, 0.1);
    }
  });

  const positionY = -sectionHeight * offset * factor;

  return (
    <offsetContext.Provider value={offset}>
      <group position={[0, positionY, 0]}>
        <group ref={ref}>{children}</group>
      </group>
    </offsetContext.Provider>
  );
};
