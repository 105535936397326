import { Canvas } from '@react-three/fiber';
import { useMemo } from 'react';
import styled from 'styled-components';
import { Bloom, EffectComposer } from '@react-three/postprocessing';

import { Station as Model } from './3D/station';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Station = () => {
  const station = useMemo(() => {
    return <Model />;
  }, []);

  return (
    <Container>
      <Canvas
        camera={{ position: [0, 6, 150], fov: 50 }}
        onCreated={({ gl }) => {
          gl.shadowMap.enabled = true;
        }}
      >
        <hemisphereLight intensity={0.1} groundColor="black" />
        <ambientLight intensity={0.3} />
        {station}
        <EffectComposer disableNormalPass>
          <Bloom luminanceThreshold={0} mipmapBlur luminanceSmoothing={0.001} intensity={0.5} />
        </EffectComposer>
      </Canvas>
    </Container>
  );
};
