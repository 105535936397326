import { useBox } from '@react-three/cannon';
import { useMatcapTexture } from '@react-three/drei';
import { extend, useFrame } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import { InstancedMesh, MeshMatcapMaterial, SRGBColorSpace } from 'three';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import helvetiker from 'three/examples/fonts/helvetiker_regular.typeface.json';

extend({ TextGeometry });
const font = new FontLoader().parse(helvetiker);
const returnGeometry = (text: string, size: number) => {
  return new TextGeometry(text, {
    font: font,
    size: size,
    height: 0.1,
  });
};

const material = new MeshMatcapMaterial();

interface LetterProps {
  letter: string;
  size: number;
  count: number;
  position: number[];
}

export const Letters = ({ letter, size, count, position }: LetterProps) => {
  const [matcapTexture] = useMatcapTexture('37C337_279F27_186018_248824', 256);

  useEffect(() => {
    matcapTexture.colorSpace = SRGBColorSpace;
    matcapTexture.needsUpdate = true;
    material.matcap = matcapTexture;
    material.needsUpdate = true;
  }, [matcapTexture]);

  const [ref, { at }] = useBox(
    () => ({
      args: [size, size, size],
      mass: 12,
      fixedRotation: true,
      position: [0, 7, 0],
    }),
    useRef<InstancedMesh>(null),
  );

  useFrame(() => at(Math.floor(Math.random() * count)).position.set(position[0], Math.random() * 15, position[2]));
  return <instancedMesh ref={ref} receiveShadow castShadow args={[returnGeometry(letter, size), material, count]} />;
};
