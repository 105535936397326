import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyle from './styles/global';
import { store } from './redux/store';

import IframeWrapper from './components/iFrameWrapper';
import { Overlay } from './components/overlay';
import { Home } from './components/home';
import { ROUTES } from './redux/slices/route-slice';
import { Earth } from './components/globe/earth';
import { theme } from './styles/theme';
import { Station } from './components/station';

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.black};
`;

const App: React.FC = () => (
  <Provider store={store}>
    <ThemeProvider theme={{ theme }}>
      <GlobalStyle />
      <HashRouter>
        <MainContainer theme={theme}>
          <Routes>
            <Route path="/" element={<Navigate to="home" replace={true} />} />
            <Route path={ROUTES.HOME} element={<Home />} />
            <Route path={ROUTES.FLOW} element={<IframeWrapper />} />
            <Route path={ROUTES.TARGET} element={<IframeWrapper />} />
            <Route path={ROUTES.PYRAMIDS} element={<IframeWrapper />} />
            <Route path={ROUTES.EARTH} element={<Earth />} />
            <Route path={ROUTES.HOPE} element={<IframeWrapper />} />
            <Route path={ROUTES.TREE} element={<IframeWrapper />} />
            <Route path={ROUTES.STATION} element={<Station />} />
          </Routes>
          <Overlay />
        </MainContainer>
      </HashRouter>
    </ThemeProvider>
  </Provider>
);

export default App;
