import { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import styled from 'styled-components';
import { useScroll } from '@react-spring/web';
import { useDispatch, useSelector } from 'react-redux';
import { Bloom, EffectComposer } from '@react-three/postprocessing';

import { STATE } from '../../hooks/useSection';
import { COLOR_1, COLOR_2, COLOR_3 } from '../../types/enums';
import { setPage } from '../../redux/slices/homePage-slice';
import { HTMLContent } from '../html';
import Mask from '../3D/mask';
import { FooterControls } from './controls';
import { Loader } from '../loader';
import { Physics } from '@react-three/cannon';
import { Arrow, Divider } from '../visit-card/common';
import { theme } from '../../styles/theme';
import { selectInterface, setBodyPadding, setScroll } from '../../redux/slices/interface-slice';
import { BODY_PADDING } from '../overlay';
import { Letters } from '../3D/letters';
import { Screen } from '../3D/screen';

const Container = styled.div<{ padding: number; sketchMode: boolean }>`
  width: 100%;
  height: 100%;
  border: ${({ padding, theme, sketchMode }) => (!sketchMode ? `${padding}px solid ${theme.colors.black}` : 'none')};
`;

const ScrollArea = styled.div<{ sketches: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: ${({ sketches }) => (sketches ? 'hidden' : 'auto')};
  overflow-x: hidden;
  z-index: ${({ sketches }) => (sketches ? 0 : 999)};
  display: inline;
`;

const StickyArea = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

const ScrollArrow = styled.div`
  position: absolute;
  bottom: 50px;
  left: 50px;
  width: 130px;
  height: 130px;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: 'Roboto Mono', monospace;
  font-weight: 600;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid ${({ theme }) => theme.colors.green};
  transition: all 0.4s ease-in-out;

  > span {
    margin-top: 80px;
  }
`;

const Title = styled.h1<{ show: boolean; left?: number }>`
  position: absolute;
  top: 0;
  left: ${({ left }) => left}px;
  padding: 0 10px;
  font-weight: 600;
  font-size: 4rem;
  height: ${BODY_PADDING}px;
  color: ${({ theme }) => theme.colors.green};
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.5);
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: all 0.5s ease-in-out;

  > span {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.white};
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    margin-top: 100px;
  }
`;

export const Home = () => {
  const domContenRef = useRef<HTMLDivElement | null>(null);
  // eslint-disable-next-line
  const scrollArea = useRef<HTMLDivElement>(null!);
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const onScroll = (event: { target: any | null }) => {
    const state = { ...STATE.top };
    state.current = event.target?.scrollTop;
    STATE.top = state;
  };
  const [textOpen, setTextOpen] = useState(false);
  const [look, setLook] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const scroll = useSelector(selectInterface).scroll;
  const sketchMode = useSelector(selectInterface).sketchesMode;
  const padding = useSelector(selectInterface).bodyPadding;

  useEffect(() => {
    if (sketchMode) {
      dispatch(setPage({ number: 3, color: COLOR_1, position: -150 }));
      setTextOpen(true);
      setLook(false);
    } else {
      if (scroll <= 0.33) {
        dispatch(setPage({ number: 1, color: COLOR_1, position: 150 }));
        setTextOpen(false);
        setLook(false);
      }
      if (0.33 <= scroll && scroll <= 0.66) {
        setTextOpen(true);
        dispatch(setPage({ number: 2, color: COLOR_2, position: 0 }));
        setLook(true);
      }
      if (scroll >= 0.66) {
        setTextOpen(true);
        setLook(true);
        dispatch(setPage({ number: 3, color: COLOR_3, position: -150 }));
      }
    }
    dispatch(setBodyPadding(scroll * 80));
  }, [scroll, dispatch, sketchMode]);

  useScroll({
    container: scrollArea,
    onChange: ({ value: { scrollYProgress } }) => {
      !sketchMode && dispatch(setScroll(scrollYProgress));
    },
    default: {
      immediate: true,
    },
  });

  const letters = useMemo(() => {
    return (
      <>
        <Letters letter={'0'} size={0.4} count={20} position={[6, Math.random() * 10, 133]} />;
        <Letters letter={'1'} size={0.4} count={20} position={[6, Math.random() * 10, 133]} />;
      </>
    );
  }, []);

  const screen = useMemo(() => <Screen />, []);

  const handleAbout = useCallback(() => {
    setShowAbout(!showAbout);
  }, [showAbout]);

  const mask = useMemo(
    () => <Mask look={look} showAbout={showAbout} handleAbout={handleAbout} />,
    [look, showAbout, handleAbout],
  );

  return (
    <Container padding={!sketchMode ? padding : 80} theme={theme} sketchMode={sketchMode}>
      <Canvas
        camera={{ position: [0, 6, 150], fov: 50 }}
        eventSource={document.getElementById('root') as HTMLElement}
        eventPrefix="client"
        gl={{ antialias: true, alpha: true }}
      >
        <Suspense fallback={<Loader />}>
          <Physics iterations={3}>
            <hemisphereLight intensity={0.1} groundColor="black" />
            <ambientLight intensity={0.3} />
            <directionalLight position={[0, 10, 0]} intensity={0.5} />
            {letters}
            {screen}
            {mask}
            <EffectComposer disableNormalPass>
              <Bloom luminanceThreshold={0} mipmapBlur luminanceSmoothing={0.001} intensity={0.5} />
            </EffectComposer>
            <HTMLContent domContent={domContenRef} />
            <HTMLContent domContent={domContenRef} />
            <HTMLContent domContent={domContenRef} />
          </Physics>
        </Suspense>
      </Canvas>
      <ScrollArea ref={scrollArea} onScroll={onScroll} sketches={useSelector(selectInterface).sketchesMode}>
        <StickyArea ref={domContenRef}>
          {!textOpen ? (
            <ScrollArrow theme={theme}>
              <Arrow vertical />
              <span>scroll</span>
            </ScrollArrow>
          ) : null}
          <Title theme={theme} show={showAbout || !sketchMode} left={useSelector(selectInterface).bodyPadding}>
            morphoix <span>portal</span>
            <Divider theme={theme} width={scroll * 100} />
          </Title>
          {!sketchMode && <FooterControls textOpen={textOpen} showAbout={showAbout} handleAbout={handleAbout} />}
        </StickyArea>
        <div style={{ height: `${STATE.pages * 100}vh` }} />
      </ScrollArea>
    </Container>
  );
};
