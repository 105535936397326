// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { WebGLRenderer, Scene, PerspectiveCamera, DirectionalLight, Color, PointLight, Vector2 } from 'three';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import ThreeGlobe from 'three-globe';

import countries from './countries.json';

let scene: THREE.Scene;
let composer: EffectComposer;
let camera: THREE.PerspectiveCamera;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let Globe: any;
let renderer: THREE.WebGLRenderer;
const mouseY = 0;

const params = {
  exposure: 0.4,
  bloomStrength: 0.3,
  bloomThreshold: 0,
  bloomRadius: 0.2,
};

const width: number = window.innerWidth - 160;
const height: number = window.innerHeight - 160;

const CanvasWrapper = styled.div`
  align-self: center;
  height: 100%;
  width: 100%;
`;

export const Earth = () => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function init() {
      renderer = new WebGLRenderer({ antialias: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(width, height);

      if (ref.current) {
        ref.current.appendChild(renderer.domElement);
      }
      scene = new Scene();
      scene.background = new Color(0x000);

      camera = new PerspectiveCamera();
      camera.aspect = width / height;
      camera.updateProjectionMatrix();

      const dLight = new DirectionalLight(0xffff3f, 1);
      dLight.position.set(-800, 700, 400);

      const dLight1 = new DirectionalLight(0xffffff, 1);
      dLight1.position.set(-200, 500, 200);

      const dLight2 = new PointLight(0xffff3f, 1);
      dLight2.position.set(-200, 500, 200);
      camera.add(dLight, dLight1, dLight2);

      camera.position.z = -200;
      camera.position.x = 0;
      camera.position.y = 0;
      scene.add(camera);

      Globe = new ThreeGlobe();
      Globe.hexPolygonsData(countries.features)
        .hexPolygonResolution(2)
        .hexPolygonMargin(0.2)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .hexPolygonColor((e: any) => {
          if (['KGZ', 'KOR', 'THA', 'RUS', 'UZB', 'IDN', 'KAZ', 'MYS'].includes(e.properties.ISO_A3)) {
            return '#08a045';
          } else return '#ffea00';
        })
        .showGlobe(false)
        .showAtmosphere(true)
        .atmosphereColor('#014737')
        .atmosphereAltitude(0.2)
        .rotateY(-Math.PI * (5 / 9))
        .rotateZ(-Math.PI / 6);
      scene.add(Globe);
      Globe.position.x = -20;

      const renderScene = new RenderPass(scene, camera);
      const bloomPass = new UnrealBloomPass(new Vector2(width, height), 1, 0.4, 0.85);
      bloomPass.threshold = params.bloomThreshold;
      bloomPass.strength = params.bloomStrength;
      bloomPass.radius = params.bloomRadius;

      composer = new EffectComposer(renderer);
      composer.addPass(renderScene);
      composer.addPass(bloomPass);
    }

    function onWindowResize() {
      const width = window.innerWidth - 180;
      const height = window.innerHeight - 180;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    }

    window.addEventListener('resize', onWindowResize, false);

    function animate() {
      Globe.rotation.y += 0.0005;
      // camera.position.x += Math.abs(mouseX) <= width / 2 / 2 ? (mouseX / 2 - camera.position.x) * 0.005 : 0;
      camera.position.y += (-mouseY / 2 - camera.position.y) * 0.005;
      camera.lookAt(scene.position);
      composer.render(scene, camera);
      requestAnimationFrame(animate);
    }

    init();
    animate();
    return () => {
      if (ref.current) {
        // eslint-disable-next-line
        ref.current.removeChild(ref.current.lastChild!);
      }
    };
  }, []);

  return <CanvasWrapper ref={ref} />;
};
