import { Merged, useGLTF } from '@react-three/drei';
import { ReactElement, useMemo } from 'react';
import * as THREE from 'three';
import { type GLTF } from 'three-stdlib';
import { useFrame } from '@react-three/fiber';

const URL = '/assets/models/station-draco-transformed.glb';

// eslint-disable-next-line
type GLTFResult = GLTF & {
  nodes: {
    MOD_CameraStation_Open003: THREE.Mesh;
    MOD_CameraStation_Open004: THREE.Mesh;
    MOD_CameraStation_Open005: THREE.Mesh;
  };
  materials: {
    ['MAT_CameraStationCase.AR']: THREE.MeshStandardMaterial;
    ['Material.ar']: THREE.MeshStandardMaterial;
    ['MAT_CameraStationScreen.002']: THREE.MeshStandardMaterial;
  };
};

type Memoized = { Object1: () => ReactElement; Object2: () => ReactElement; Object3: () => ReactElement };

export const Station = () => {
  const { nodes } = useGLTF(URL) as GLTFResult;

  const instances = useMemo(
    () => ({
      Object1: nodes.MOD_CameraStation_Open003,
      Object2: nodes.MOD_CameraStation_Open004,
      Object3: nodes.MOD_CameraStation_Open005,
    }),
    [nodes],
  );

  useFrame((state) => {
    state.camera.position.x = (state.pointer.x * state.viewport.width) / 10000;
    state.camera.position.y = 2.5 + (state.pointer.y * state.viewport.height) / 10000;
  });

  return (
    <Merged castShadow receiveShadow meshes={instances}>
      {({ Object1, Object2, Object3 }: Memoized) => (
        <group>
          <mesh receiveShadow castShadow position={[0, 0, 145]} scale={3} rotation={[0, -0.3, 0]}>
            <Object1 />
            <Object2 />
            <Object3 />
          </mesh>
          <mesh receiveShadow castShadow position={[3, 1.5, 142.4]} rotation={[0, -0.83, 0]} scale={3}>
            <Object1 />
            <Object2 />
            <Object3 />
          </mesh>
          <mesh receiveShadow castShadow position={[-3, 1, 140]} rotation={[0, 0.73, 0]} scale={3}>
            <Object1 />
            <Object2 />
            <Object3 />
          </mesh>
        </group>
      )}
    </Merged>
  );
};

useGLTF.preload(URL);
