import { usePlane } from '@react-three/cannon';
import { MeshReflectorMaterial } from '@react-three/drei';
import { useRef } from 'react';

interface PlaneProps {
  rotation: [number, number, number];
  position: [number, number, number];
}

export const RigidPlane = (props: PlaneProps) => {
  const [ref] = usePlane(() => ({ type: 'Static', ...props }), useRef<THREE.Mesh>(null));

  return (
    <mesh receiveShadow rotation={[-Math.PI / 2, 0, 0]} ref={ref}>
      <planeGeometry args={[30, 20]} />
      <MeshReflectorMaterial
        blur={[300, 30]}
        mirror={1}
        resolution={2048}
        mixBlur={1}
        mixStrength={80}
        roughness={1}
        depthScale={1.2}
        minDepthThreshold={0.4}
        maxDepthThreshold={1.4}
        color="#fff"
        metalness={0.2}
      />
    </mesh>
  );
};
