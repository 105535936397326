import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export enum ROUTES {
  HOME = 'home',
  FLOW = 'flow',
  TARGET = 'target',
  PYRAMIDS = 'gyza',
  EARTH = 'earth',
  HOPE = 'hope',
  TREE = 'tree',
  STATION = 'station',
}

const renderLegacyUrl = (route: string) => {
  return `./assets/html/${route}.html`;
};

export const routes: Route[] = [
  {
    id: 0,
    route: '/' + ROUTES.HOME,
    title: 'mask',
    data: '2023',
    description: 'Custom shader material and physics simulation',
    tools: 'R3F, cannon.js, GLSL',
  },
  {
    id: 1,
    route: '/' + ROUTES.STATION,
    title: 'station',
    data: 'work in progress',
    description: '-',
    tools: 'R3F',
  },
  {
    id: 2,
    path: renderLegacyUrl(ROUTES.FLOW),
    route: '/' + ROUTES.FLOW,
    title: ROUTES.FLOW,
    data: '2019',
    description: 'TubeGeometry vertices animation using perlin noise, colors conversion, AfterImagePass',
    tools: 'vanilla JS, three 124, perlin noise',
  },
  {
    id: 3,
    path: renderLegacyUrl(ROUTES.TARGET),
    route: '/' + ROUTES.TARGET,
    title: ROUTES.TARGET,
    data: '2020',
    description: 'SplineCurveGeometry targeting with 4 camera views',
    tools: 'vanilla JS, three 128',
  },
  {
    id: 4,
    route: '/' + ROUTES.EARTH,
    title: ROUTES.EARTH,
    data: '2022',
    description: 'This project enhances the visual effects and rendering of the globe using post-processing techniques',
    tools: 'ThreeGlobe. three.js postprocessing',
  },
  {
    id: 5,
    route: '/' + ROUTES.HOPE,
    path: renderLegacyUrl(ROUTES.HOPE),
    title: ROUTES.HOPE,
    data: '2020',
    description: 'Hope that is put off makes the heart sick, but a desire that comes into being is a tree of life.',
    tools: 'vanilla.js, three 128, postprocessing',
  },
  {
    id: 6,
    path: renderLegacyUrl(ROUTES.PYRAMIDS),
    route: '/' + ROUTES.PYRAMIDS,
    title: ROUTES.PYRAMIDS,
    data: '2019',
    description: 'Reconstruction of the Giza Pyramid Complex using RectAreaLigh and LensFlare, legacy',
    tools: 'vanilla JS, three 128',
  },
  {
    id: 7,
    path: renderLegacyUrl(ROUTES.TREE),
    route: '/' + ROUTES.TREE,
    title: ROUTES.TREE,
    data: '2019',
    description: 'Postprocessing and particles animation, legacy',
    tools: 'vanilla JS, three 128',
  },
];

export type Route = {
  id: number;
  path?: string;
  route: string;
  title: string;
  data?: string;
  description?: string;
  tools?: string;
};

const initialState: Route = routes[0];

export const routeSlice = createSlice({
  name: 'route',
  initialState,
  reducers: {
    setRoute: (state, action) => {
      const route = routes.find((route) => route.route === action.payload);
      return route;
    },
  },
});

export const selectRoute = (state: RootState) => {
  return state.route;
};

export const { setRoute } = routeSlice.actions;
export default routeSlice.reducer;
