import styled from 'styled-components';

import { Title } from './title';
import { Contacts } from './contacts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  align-items: flex-end;
  justify-content: space-between;
  height: 100vh;
`;

export const VisitCard = () => {
  return (
    <Container>
      <Title />
      <Contacts />
    </Container>
  );
};
