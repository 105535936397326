import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Odyssee ITC Std Light';
    src: url('./assets/fonts/Odyssee.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  #root {
    margin: 0 auto;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  body {
    margin: 0;
    font-family: 'Odyssee ITC Std Light';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scrollbar-width: 3px;
    scrollbar-track-color: #addbfc;
    scrollbar-color: #0c0c0c;
    transition: background-color 400ms linear;
    background-color: #0c0c0c;
  }

  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    padding: 40px 0;
  }

  ::-webkit-scrollbar-track {
    background: #0c0c0c;
  }

  ::-webkit-scrollbar-thumb {
    background: #03fe86;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: white;
    cursor: pointer;
  }

  a {
    text-decoration: none;
    transition: all 0.5s;
    font-size: 1.5em;
    color: #0c0c0c;
  }

  iframe {
    border: none;
  }
`;

export default GlobalStyle;
